<template>
  <div class="item-box">
    <div class="title">
      <div v-if="currentNum === 0">
        <img class="title-img" src="@/assets/image/btctrend/haiwai.png" />
        海外用户
      </div>
      <div v-if="currentNum === 1">
        <img class="title-img" src="@/assets/image/btctrend/guanfang.png" />
        官方机构/组织
      </div>
      <div v-if="currentNum === 2">
        <img class="title-img" src="@/assets/image/btctrend/guonei.png" />
        国内用户
      </div>
      <div class="title-tips"><a style="color: red;">*</a>国内/跨境线上展会，仅展示部分用户。（云展位开发中...）</div>
    </div>
    <!--表格部分-->
    <div class="table-box">
      <div class="table-body">
        <div v-for="(item, index) in arry" :key="index" style="display: flex;justify-content: flex-start;width: 100%;padding-left: 10px;">
          <div class="ul-item" v-for="(itemChild, index2) in item" :key="index2">
            <div class="li-item" >
            <div class="item-body">
              <div class="item-left">
                <div class="item-left-top">{{itemChild.Name}}</div>
                 <div class="item-left-bot">{{itemChild.NameEn}}</div>
              </div>
               <div class="item-right"> <img :src="itemChild.src" style="width: 115px;"/></div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'
import '@/styles/tooltip.css'

export default {
  mixins: [commonmix],
  name: 'company-trend',
  props: {
    currentNum: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      arry: [],
      list: [],
      indexQuery: {
        PageNum: 1,
        PageSize: 39
      },
      isTrueTxt: '海外用户'
    }
  },
  watch: {
    currentNum (val) {
      switch (val) {
        case 0:
          this.isTrueTxt = '海外用户'
          break
        case 1:
          this.isTrueTxt = '官方机构/组织'
          break
        case 2:
          this.isTrueTxt = '国内用户'
          break
        default:
      }
      this.arry = []
      this.list = []
      this.getList(false)
    }
  },
  computed: {
    userid () {
      return getLocalStore().UserID
    }
  },
  mounted () {
    this.getList()
    var _self = this
    if (document.getElementsByClassName('table-body')[0]) {
      document.getElementsByClassName('table-body')[0].addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop === this.clientHeight) {
          _self.getList(true)
        }
      })
    }
  },
  methods: {
    getList (isMore) {
      if (isMore) {
        this.indexQuery.PageNum += 1
      } else {
        this.indexQuery.PageNum = 1
      }
      this.indexQuery.Type = this.isTrueTxt
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store
        .dispatch('GetBeefCompanyTrend', this.indexQuery)
        .then(() => {
          if (isMore) {
            this.list = this.list.concat(this.$store.getters.dataTable[0].BeefTrendList)
          } else {
            this.list = this.$store.getters.dataTable[0].BeefTrendList
          }
          this.getIcon()
          this.$store.dispatch('SetLoadingStatus', false)
        })
        .catch(err => {
          if (err.ErrorCode === 6004) {
            this.$router.push('/english/charge')
          }
          this.$store.dispatch('SetLoadingStatus', false)
        })
    },
    getIcon () {
      this.list.forEach(item => {
        if (item && item.Logo) {
          item.src = `${process.env.VUE_APP_STATIC}${item.Logo}`
        }
        return null
      })
      this.arry = this.list.reduce((pre, next, idx) => { // reduce 用来便利数组，具体语法就 rtfm 吧
        const inner = pre[~~(idx / 3)] // ~~用来取整，inner 是内层数组
        if (inner !== undefined) { // 判断有没有内层数组
          inner.push(next) // 如果有就把遍历的值 next push 到内层数组里
        } else {
          pre.push([next]) // 没有就新建一个包含 next 的数组，作为内层数组
        }
        return pre
      }, [[]])
    }
  }
}
</script>

<style lang="scss" scoped>
$cell-width: 170px;
$cell-height: 52px;

.item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h1 {
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0 10px 0;
}

.title {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
  opacity: 1;
  padding: 40px 0 0 0;
  text-align: center;
  align-items: center;
}

.title-img {
  height: 37px;
  margin-right: 3px;
  margin-bottom: 10px;
}

.title-tips{
  font-size: 11px;
  font-weight: 400;
  color: #4E4E4E;
}

.picker {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

::-webkit-scrollbar {
  display: none;
}

.table-body {
  margin-top: 27px;
  padding-top: 10px;
  height: 1000px;
  min-width: 1200px;
  overflow-y: scroll;
  // display: flex;
  justify-content: space-between;
}

.trans {
  transform: rotate(180deg);
}

.plant-state {
  min-width: 60px;
  background: #28a947;
  color: #ffffff;
  padding: 5px;
  display: inline-block;
  border-radius: 5px !important;
  font-weight: lighter;
  transform: scale(0.9);
}

.fb {
  font-weight: bold;
}

.stop {
  background: #d13636 !important;
}

.ul-item{
  margin: 0 10px 18px 0px;
}

.li-item{
  width: 380px;
  height: 100px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
}

.item-body{
  width: 90%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.item-left{
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.item-right{
  // margin-top: 8px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.item-left-top{
  height: 15px;
  font-size: 13px;
  color: #4E4E4E;
  // opacity: 0.65;
  // margin-bottom: 8px;
  margin-top: 15px;
  font-weight: bold;
  line-height: 16px;
}

.item-left-bot{
  height: 25px;
  font-size: 11px;
  color: #4E4E4E;
  line-height: 13px;
  opacity: 1;
  font-weight: 500;
  // opacity: 0.65;
  margin-bottom: 15px;
}
</style>
