<template>
  <div class="content-box">
    <div style="height: 10px"></div>
    <div class="content-button">
      <div v-for="(item, index) in list" :key="index" :class="{ active: current === index }" @click="handleButton(index)">
        <div style="align-items: center;text-align: center;cursor: pointer;">
          <div class="content-img">
            <img v-if="item.isChecked === 0" class="title-img-false" :src="item.srcFalse" />
            <img v-if="item.isChecked === 1" class="title-img-true" :src="item.srcTrue" />
          </div>
          <!--
          <div class="content-body" :class="{ contentbody5: index === 5 }">
            {{ item.name }}
          </div>
          -->
        </div>
      </div>
    </div>
    <CompanyTrend :currentNum="current"/>
  </div>
</template>

<script>
import CompanyTrend from './components/company-trend'

export default {
  name: 'CnBTCest',
  components: {
    CompanyTrend
  },
  data () {
    return {
      buttons: ['海外用户', '官方机构/组织', '国内用户'],
      list: [
        {
          name: '海外用户',
          src: require('@/assets/image/btctrend/haiwai0.png'),
          srcFalse: require('@/assets/image/btctrend/haiwai0.png'),
          srcTrue: require('@/assets/image/btctrend/haiwai1.png'),
          isChecked: 1
        },
        {
          name: '官方机构/组织',
          src: require('@/assets/image/btctrend/guanfang0.png'),
          srcFalse: require('@/assets/image/btctrend/guanfang0.png'),
          srcTrue: require('@/assets/image/btctrend/guanfang1.png'),
          isChecked: 0
        },
        {
          name: '国内用户',
          src: require('@/assets/image/btctrend/guonei0.png'),
          srcFalse: require('@/assets/image/btctrend/guonei0.png'),
          srcTrue: require('@/assets/image/btctrend/guonei1.png'),
          isChecked: 0
        }
      ],
      current: 0
    }
  },
  methods: {
    handleButton (index) {
      console.log(index)
      this.current = index
      this.list.forEach((item, index) => {
        if (item.isChecked === 1) {
          item.isChecked = 0
        }
      })
      this.list[index].isChecked = 1
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.btc-banner{
  height: 220px;
  width: 100%;
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
}

.content-button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0 30px 0;
  border-bottom: 2px solid #f5f5f5;
  min-width: 1200px;
}
.content-body{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: auto;
  min-width: auto;
  margin: 0 10px;
  padding: 8px 16px;
  // background-color: #ffffff;
  // border-radius: 8px;
  // box-shadow: 1px 1px 1px #e1e1e1;
  // border: 1px solid #bdbdbd;
  font-weight: bold;
  transition: all 0.2s;
  cursor:pointer;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.active {
  // color: #ffffff;
  // background-color: #014379!important;
}

.content-img{
  height: 65px;
}

.title-img-false{
  height: 65px;
}

.title-img-true{
  height: 65px;
}

.contentbody5{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: auto;
  min-width: auto;
  margin: 0 10px;
  padding: 8px 0px;
  font-weight: bold;
  transition: all 0.2s;
  cursor:pointer;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}
</style>
